/*
 * External Dependencies
 */
// import 'jquery';
// import 'bootstrap';

// $(document).ready(() => {
//   // console.log('Hello world');
// });
document.getElementById("nav-toggle").addEventListener("click", toggleNav);
function toggleNav() {
  var nav = document.getElementById("navbarExampleTransparentExample");
  var className = nav.getAttribute("class");
  if (className == "navbar-menu") {
    nav.className = "nav-right navbar-menu is-active";
  } else {
    nav.className = "navbar-menu";
  }
}
